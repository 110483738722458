import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import lodash from 'lodash';

import './Increase_Decrease_Input.scss';

export default class Increase_Decrease_Input extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: lodash.get(props, 'value'),
            min: lodash.get(props, 'min'),
            max: lodash.get(props, 'max'),
            step: lodash.get(props, 'step', 1)
        }
    };

    clickMinus = () => {
        if (this.state.value >= this.state.min + this.state.step) {
            this.setState({ value: this.state.value - this.state.step }, () => {
                this.props.onChange(this.state.value);
            });
        }
    }


    clickPlus = () => {
        if (this.state.value <= this.state.max - this.state.step) {
            this.setState({ value: this.state.value + this.state.step }, () => {
                this.props.onChange(this.state.value);
            });
        }
    }

    render() {
        return (
            <div className="component-increase-decrease-input">
                <button className="button-actions" onClick={this.clickMinus.bind()}>
                    <FontAwesomeIcon icon={faMinus} />
                </button>
                <input disabled id="Increase_Decrease_Input"
                    className="input-increase-decrease"
                    onChange={this.props.onChange}
                    value={this.state.value} />
                <button className="button-actions" onClick={this.clickPlus.bind()}>
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
        );
    };
}