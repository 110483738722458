import React from 'react';

import Home from './pages/Home/Home';
import Game from './pages/Game/Game';

import './App.scss';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfContainers: 0,
      numberOfValuesPerContainer: 4
    };
  }

  onGenerateLevel = data => {
    this.setState({ numberOfContainers: data.numberOfContainers, numberOfValuesPerContainer: data.numberOfValuesPerContainer });
  };

  onReturnHome = () => {
    this.setState({ numberOfContainers: 0, numberOfValuesPerContainer: 4 });
  }


  render() {
    return (
      <div className="App">
        {this.state.numberOfContainers === 0 &&
          <Home onGenerateLevel={this.onGenerateLevel} />
        }

        {this.state.numberOfContainers !== 0 &&
          <Game numberOfContainers={this.state.numberOfContainers} numberOfValuesPerContainer={this.state.numberOfValuesPerContainer} onGenerateLevel={this.onGenerateLevel} onReturnHome={this.onReturnHome} />
        }
      </div>
    );
  };
}