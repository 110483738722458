import React from 'react';
// import Slider from '../../components/Slider/Slider';
import Logo from '../../components/Logo/Logo';
import IncreaseDecreaseInput from '../../components/Increase_Decrease_Input/Increase_Decrease_Input';

import './Home.scss';

const levelMin = 4;
const levelMax = 10;

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.scrollElement = React.createRef();
        this.state = {
            hasPersonalized: false,
            valueNumberColor: 8,
            valueNumberOfValuesPerContainer: 4
        };
    }

    onInputNumberColorChange = value => {
        this.setState({ valueNumberColor: value });
    };

    onInputNumberOfValuesPerContainerChange = value => {
        this.setState({ valueNumberOfValuesPerContainer: value });
    };

    launchGame = () => {
        const level = Math.floor(Math.random() * (levelMax - levelMin + 1)) + levelMin;

        this.props.onGenerateLevel({ numberOfContainers: level, numberOfValuesPerContainer: 4 });
    };

    personalizeDifficulty = () => {
        this.setState({ hasPersonalized: !this.state.hasPersonalized }, function () {
            if (this.state.hasPersonalized) {
                setTimeout(() => {
                    this.scrollElement.current.scrollIntoView({ behavior: "smooth" });
                }, 1);
            }
        });
    }

    render() {
        return (
            <div className="Home">
                <Logo />

                <div className="divContainerButtons">
                    <button type="button" onClick={this.launchGame} className="button button-play">
                        <span>Jouer !</span>
                    </button>

                    <button type="button" onClick={this.personalizeDifficulty} className="button button-personalize">
                        <span>Personnaliser</span>
                    </button>

                    {/* Display when user click on button "Personnaliser" */}
                    {this.state.hasPersonalized &&
                        <div className="div-personalize-level" ref={this.scrollElement}>
                            <span className="span-select-number-of-colors">Selectionnez la difficulté :</span>

                            <div className="div-slider-select-difficulty">
                                <span className="span-select-difficulty">Nombre de couleurs :</span>
                                {/* <Slider onChange={this.onInputChange} /> */}

                                <IncreaseDecreaseInput onChange={this.onInputNumberColorChange} value={8} min={4} max={20} />
                            </div>

                            <div className="div-slider-select-difficulty">
                                <span className="span-select-difficulty">Nombre de balles par tube :</span>
                                {/* <Slider onChange={this.onInputChange} /> */}

                                <IncreaseDecreaseInput onChange={this.onInputNumberOfValuesPerContainerChange} value={4} min={2} max={5} />
                            </div>

                            <div>
                                <button onClick={this.props.onGenerateLevel.bind(this, { numberOfContainers: this.state.valueNumberColor, numberOfValuesPerContainer: this.state.valueNumberOfValuesPerContainer })} className="button button-generate-level-personalized">
                                    Générer un niveau
                                </button>
                            </div>
                        </div>
                    }
                </div>

                <div className="footer">
                    <span className="span-made-by">Made with ❤️ by Pierre R.</span>
                </div>
            </div>
        );
    }
}