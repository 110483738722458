import React from 'react';
import lodash from 'lodash';

import './Popup_Confirmation.scss';

export default class Popup_Confirmation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            text: lodash.get(props, 'text'),
            canConfirm: lodash.get(props, 'canConfirm'),
            canCancel: lodash.get(props, 'canCancel')
        }
    };

    onCancel = () => {
        this.props.onCancel();
    };

    onValidate = () => {
        this.props.onValidate();
    };

    render() {
        return (
            <div className="popup">
                <p>{this.state.text}</p>

                <div className="div-btn-actions">
                    {this.state.canCancel &&
                        <button onClick={this.onCancel.bind()} className="btn-cancel">Annuler</button>
                    }

                    {this.state.canConfirm &&
                        <button onClick={this.onValidate.bind()} className="btn-validate">Ok</button>
                    }
                </div>
            </div>
        );
    };
}