import emailjs from 'emailjs-com';

export function sendEmail(data) {
    let htmlDataForMail = "";

    data.containers.forEach((element, index) => {
        htmlDataForMail += `Container n° ${index} : \n`;
        element.data.forEach(e => {
            htmlDataForMail += `${e.value}`;
        })
        htmlDataForMail += `\n\n`;
    });

    htmlDataForMail += `\n\n`;
    htmlDataForMail += `Nombre de coups : ${data.strokes}\n\n`;
    htmlDataForMail += `Temps : ${data.time}`;

    emailjs.send('service_xdt54a7', 'template_rhqo3cu', { message: htmlDataForMail }, 'user_N2LNHcytXehp5SlRn1snx')
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
        }, (err) => {
            console.log('FAILED...', err);
        });

    return true;
}