import React from 'react';

import { render } from 'react-dom';
import lodash from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSyncAlt, faUndoAlt, faPlus, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import LottieAnimation from '../../components/Lottie/Lottie';
import lottieConfettiVictory from '../../assets/lottie-confetti-victory.json';
import lottieConfettiCompleteOneContainer from '../../assets/lottie-confetti-complete-one-container.json';
import ms from 'pretty-ms';

import './Game.scss';
import { sendEmail } from 'services/EmailJS';
import PopupConfirmation from 'components/Popup_Confirmation/Popup_Confirmation';

var dataOfContainers = [];

var numberOfValuesPerContainer = 4;
var numberOfContainers = 10;
var numberOfContainersEmpty = 2;
// var numberTotalOfContainers = numberOfContainers + numberOfContainersEmpty;

const levelMin = 4;
const levelMax = 10;

var haveWon = false;

const listOfValuesData = [
  { value: "A", color: "#ff0000" },
  { value: "B", color: "#2b9cff" },
  { value: "C", color: "#4caf50" },
  { value: "D", color: "#ffeb3b" },
  { value: "E", color: "#ff9800" },
  { value: "F", color: "#00e7ff" },
  { value: "G", color: "#800080" },
  { value: "H", color: "#e45a84" },
  { value: "I", color: "#3f51b5" },
  { value: "J", color: "#ffacac" },
  { value: "K", color: "#8b4513" },
  { value: "L", color: "#9f4fad" },
  { value: "M", color: "#20b2aa" },
  { value: "N", color: "#c1ce15" },
  { value: "O", color: "#75574c" },
  { value: "P", color: "#4b4a4a" },
  { value: "Q", color: "#ffffff" },
  { value: "R", color: "#000000" },
  { value: "S", color: "#2e6300" },
  { value: "T", color: "#630000" },
  { value: "U", color: "#321f35" },
  { value: "V", color: "#7fff84" },
  { value: "W", color: "#9e9e9e" },
  { value: "X", color: "#0700ff" },
  { value: "Y", color: "#ffae56" },
  { value: "Z", color: "#4d00ff" },
];

class App extends React.Component {
  state = {
    containers: [],
    initialData: [],
    history: [],
    indexLastContainer: null,
    clicked: "",
    error: null,

    //Timer
    time: 0,
    start: 0,

    // Popup
    actionToDo: "",
    displayPopup: false,
    textPopup: ""
  };

  componentDidMount = () => {
    // Reset data
    numberOfContainers = Number(this.props.numberOfContainers);
    numberOfValuesPerContainer = Number(this.props.numberOfValuesPerContainer);
    haveWon = false;

    // numberTotalOfContainers = Number(numberOfContainers) + Number(numberOfContainersEmpty);

    this.setState({ containers: [] });
    dataOfContainers = [];

    this.createAllTheContainer();
    this.fillAllTheContainers();

    // Store data
    this.setState({ containers: dataOfContainers, initialData: lodash.cloneDeep(dataOfContainers) }, () => {
      this.checkAllTheContainers();
    });

    let root = document.documentElement;
    root.style.setProperty('--height-tube', 25 * numberOfValuesPerContainer + "px");
  }

  createAllTheContainer = () => {
    for (let index = 0; index < numberOfContainers; index++) {
      dataOfContainers.push({ data: [], isEmpty: true, isComplete: false, index: index });
    }
  };

  fillAllTheContainers = () => {
    listOfValuesData.forEach(element => {
      element.numberOfIterations = numberOfValuesPerContainer;
    });

    for (let index = 0; index < numberOfContainers; index++) {

      for (let i = 0; i < numberOfValuesPerContainer; i++) {
        const indexColor = this.getARandomColor()
        this.fillOneContainer(indexColor)
      }
    }

    for (let index = 0; index < numberOfContainersEmpty; index++) {
      dataOfContainers.push({ data: [], isEmpty: true, isComplete: false });
    }

    this.startTimer();
  };

  getARandomColor = () => {
    const indexColor = this.getRandomArbitrary(numberOfContainers);

    if (!listOfValuesData[indexColor].numberOfIterations) {
      return this.getARandomColor();
    } else {
      listOfValuesData[indexColor].numberOfIterations--;
      return indexColor;
    }
  }

  fillOneContainer = (index) => {
    const randomIndex = this.getRandomArbitrary(numberOfContainers);

    if (dataOfContainers[randomIndex].data.length < numberOfValuesPerContainer) {
      dataOfContainers[randomIndex].data.push(listOfValuesData[index]);

    } else {
      this.fillOneContainer(index);
    }
  };

  getRandomArbitrary = (maximum) => {
    const min = Math.ceil(0);
    const max = Math.floor(maximum);

    return Math.floor(Math.random() * (max - min)) + min;
  };

  checkAllTheContainers = () => {
    this.state.containers.forEach(container => {
      this.checkIfContainerIsComplete(container);
      this.checkIfContainerIsEmpty(container);
    });
  }

  clickOnOneContainer = (container, index) => {

    let root = document.documentElement;
    root.style.setProperty('--position-ball', 25 * container.data.length - 5 + "px");

    const array = container.data;
    const indexLastContainer = this.state.indexLastContainer;
    const value = array[array.length - 1];

    if (indexLastContainer === index) {
      this.setState({ error: 'Même tube', clicked: "", indexLastContainer: null });

    } else if (!this.state.clicked) {

      if (!container.isComplete) {
        this.setState({ clicked: value, indexLastContainer: index, error: null });

      } else {
        this.setState({ error: 'Complet', indexLastContainer: null });
      }

    } else {
      if (value === this.state.clicked || container.isEmpty) {

        if (container.data.length < numberOfValuesPerContainer) {

          // Remove the value selected
          const lastArray = this.state.containers[indexLastContainer].data;
          this.state.containers[indexLastContainer].data.splice(lastArray.length - 1, 1);

          // Check if container is empty
          this.checkIfContainerIsEmpty(this.state.containers[indexLastContainer]);

          // Push the value selected in new container
          container.data.push(this.state.clicked);

          // Check if container is complete 
          this.checkIfContainerIsComplete(container);

          // Container is no more empty
          if (container.isEmpty) container.isEmpty = false;

          //History
          this.setState(prevState => ({
            history: [...prevState.history, { from: indexLastContainer, to: index }],
            error: null,
            clicked: "",
            indexLastContainer: index
          }));

        } else {
          this.setState({ error: 'Complet', clicked: "", indexLastContainer: null });
        }
      } else {
        this.setState({ error: 'Pas la bonne valeur', clicked: "", indexLastContainer: null });
      }

      let root = document.documentElement;
      root.style.setProperty('--number-of-balls', this.state.containers[index].data.length + "px");
    }
  }

  checkIfContainerIsComplete = (container) => {
    const conditionsToCompleteContainer = [
      container.data.length === numberOfValuesPerContainer,
      container.data.every((val, i, arr) => val === arr[0])
    ];
    container.isComplete = conditionsToCompleteContainer.every(e => e);

    if (container.isComplete) {
      this.checkIfAllContainersAreComplete()
    }
  }

  checkIfAllContainersAreComplete = () => {
    let numberOfContainerComplete = 0;
    this.state.containers.forEach(container => {

      if (container.isComplete) {
        numberOfContainerComplete++;
      }
    });

    haveWon = numberOfContainerComplete === numberOfContainers;

    if (haveWon) {
      this.stopTimer();
      this.saveLevel();
    }
  }

  checkIfContainerIsEmpty = (container) => {
    container.isEmpty = container.data.length === 0;
  }

  cancelLastMove = () => {
    if (this.state.history.length) {
      const cloneContainers = lodash.cloneDeep(this.state.containers)

      const lastMove = this.state.history[this.state.history.length - 1];
      const indexLastContainer = lastMove.to;
      const lastArray = cloneContainers[indexLastContainer].data;

      // Store data before remove it
      const lastData = cloneContainers[indexLastContainer].data[lastArray.length - 1];

      // Remove last move : delete data lastly set
      cloneContainers[indexLastContainer].data.splice(lastArray.length - 1, 1);

      // Check if container is empty
      this.checkIfContainerIsEmpty(cloneContainers[indexLastContainer]);

      // Remove last move : reset data lastly removed 
      const indexNewContainer = lastMove.from;
      cloneContainers[indexNewContainer].data.push(lastData);
      this.checkIfContainerIsComplete(cloneContainers[indexNewContainer]);

      // Remove the move from history
      const cloneHistory = lodash.cloneDeep(this.state.history)
      cloneHistory.splice(cloneHistory.length - 1, 1);

      // Store data
      this.setState({ containers: cloneContainers, history: cloneHistory, clicked: "", indexLastContainer: null });
    }
  }

  getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  addAContainer = () => {
    const containers = lodash.cloneDeep(this.state.containers);
    containers.push({ data: [], isEmpty: true, isComplete: false, index: this.state.containers.length });

    let root = document.documentElement;

    const width = this.getWindowDimensions().width;
    // const height = this.getWindowDimensions().height;

    if (width <= 320) {
      if (containers.length <= 9) {
        root.style.setProperty('--gap-tube', "30px");

      } else if (containers.length > 9 && containers.length < 12) {
        root.style.setProperty('--gap-tube', "25px");

      } else if (containers.length > 12 && containers.length < 16) {
        root.style.setProperty('--gap-tube', "5px");
      }
    } else {
      if (containers.length > 25) {
        root.style.setProperty('--gap-tube', "5px");

      } else if (containers.length > 20) {
        root.style.setProperty('--gap-tube', "5px");

      } else if (containers.length > 12) {
        root.style.setProperty('--gap-tube', "20px");

      } else if (containers.length <= 12) {
        root.style.setProperty('--gap-tube', "30px");
      }
    }

    this.setState({ containers: containers });
  }

  displayPopupConfirmation = (action) => {
    let textToShow = "";

    switch (action) {
      case 'retryLevel':
        textToShow = "Êtes-vous sûr de vouloir recommencer ce niveau ?";
        break;

      case 'onReturnHome':
        textToShow = "Êtes-vous sûr de vouloir quitter et abandonner ce niveau ?";
        break;

      default:
        textToShow = "Êtes-vous sûr ?";
        break;
    };

    this.setState({ displayPopup: true, textPopup: textToShow, actionToDo: action });
    let root = document.documentElement;
    root.style.setProperty('--pointer-events', "none");
  }

  onCancelPopup = () => {
    this.setState({ displayPopup: false, actionToDo: "", textPopup: "" });

    let root = document.documentElement;
    root.style.setProperty('--pointer-events', "unset");
  }

  actionAfterPopup = () => {
    this[this.state.actionToDo]();
    this.setState({ displayPopup: false, actionToDo: "", textPopup: "" });

    let root = document.documentElement;
    root.style.setProperty('--pointer-events', "unset");
  }

  retryLevel = () => {
    this.setState({
      containers: lodash.cloneDeep(this.state.initialData),
      history: [],
      indexLastContainer: null,
      clicked: "",
    });
    this.resetTimer();
  }

  onReturnHome = () => {
    this.setState({ displayPopup: true });
    this.props.onReturnHome();
  }

  saveLevel = () => {
    const data = {
      containers: this.state.initialData,
      strokes: this.state.history.length,
      time: this.state.time
    }
    sendEmail(data);
  }


  /***** TIMER *****/

  startTimer() {
    this.setState({
      time: this.state.time,
      start: Date.now() - this.state.time
    })
    this.timer = setInterval(() => this.setState({
      time: Date.now() - this.state.start
    }), 1000);
  }

  stopTimer() {
    this.setState({ isOn: false })
    clearInterval(this.timer)
  }

  resetTimer() {
    this.setState({ time: 0, start: Date.now() })
  }

  launchGame = () => {
    numberOfContainers = Number(this.props.numberOfContainers);
    numberOfValuesPerContainer = Number(this.props.numberOfValuesPerContainer);
    haveWon = false;

    // Reset values
    this.setState({
      history: [],
      error: null,
      clicked: "",
      indexLastContainer: null,
      time: 0,
      start: Date.now()
    }, function () {
      const level = Math.floor(Math.random() * (levelMax - levelMin + 1)) + levelMin;
      this.props.onGenerateLevel({ numberOfContainers: level, numberOfValuesPerContainer: 4 });
      this.componentDidMount();
    });
  };

  render = () => {
    const listContainers = this.state.containers.map((container, index) => {
      return (
        <div>
          <li className="one-container" onClick={this.clickOnOneContainer.bind(this, container, index)}>
            {container.isComplete &&
              <div className="div-animation-lottie">
                <LottieAnimation lottie={lottieConfettiCompleteOneContainer} height={250} width={80} loop={false} />
              </div>
            }

            <ul className={`list-in-one-container 
              ${index === this.state.indexLastContainer && this.state.clicked && !this.state.error ? "animation-move-ball-up" : ""}
              ${index === this.state.indexLastContainer && !this.state.clicked && !this.state.error ? "animation-move-ball-down" : ""}`}>

              {container.data.map((data, i) => {
                return <li key={index + i} className="element-in-one-container" style={{ backgroundColor: data.color }} />
              })}
            </ul>
          </li>
        </div>
      )
    });

    return (
      <div className="Game">
        <div className="background">
          {/* <span>{this.state.containers.length}</span> */}

          {this.state.displayPopup &&
            <PopupConfirmation text={this.state.textPopup} canConfirm={true} canCancel={true} onValidate={this.actionAfterPopup.bind()} onCancel={this.onCancelPopup.bind()} />
          }

          <div className="div-buttons-actions">
            <div className="div-buttons-left">
              <button onClick={this.displayPopupConfirmation.bind(this, 'onReturnHome')} className="button-actions">
                <FontAwesomeIcon icon={faHome} />
              </button>

              {/* <button onClick={this.saveLevel.bind()} className="button-actions">
                <FontAwesomeIcon icon={faSave} />
              </button> */}
            </div>

            <div className="div-buttons-right">
              {this.state.history.length > 0 &&
                <button onClick={this.cancelLastMove.bind()} className="button-actions">
                  <FontAwesomeIcon icon={faUndoAlt} />
                </button>
              }

              <button onClick={this.addAContainer.bind()} className="button-actions">
                <FontAwesomeIcon icon={faPlus} />
              </button>

              <button onClick={this.displayPopupConfirmation.bind(this, 'retryLevel')} className="button-actions">
                <FontAwesomeIcon icon={faSyncAlt} />
              </button>
            </div>
          </div>

          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />
          <span className="span-animated-background" />

          <div className="container-of-containers">
            <ul className="list-of-containers">
              {listContainers}
            </ul>
          </div>

          <div className="game-footer-infos">
            Nombre de coup{this.state.history.length > 1 ? 's' : ''} : {this.state.history.length}

            {this.state.time > 999 &&
              <span>Temps : {ms(this.state.time, { secondsDecimalDigits: 0 })}</span>
            }
          </div>

          {
            haveWon &&
            <div className="div-victory">
              <div className="wrapper-victory">
                <div className="div-victory-lottie">
                  <LottieAnimation lottie={lottieConfettiVictory} height={300} width={300} loop={false} />
                </div>
                <div className="bg"> VICTOIRE ! </div>
                <div className="fg"> VICTOIRE ! </div>

                <div className="div-infos-level">
                  <span>
                    Nombre de coup{this.state.history.length > 1 ? 's' : ''} : {this.state.history.length}
                  </span>

                  {this.state.time > 0 &&
                    <span>Temps : {ms(this.state.time, { secondsDecimalDigits: 0 })}</span>
                  }
                </div>
              </div>

              <div className="wrapper-buttons-actions">
                <button onClick={this.launchGame} className="button-actions">
                  <span className="span-button-next">Niveau suivant</span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>

                <button onClick={this.props.onReturnHome} className="button-actions">
                  <FontAwesomeIcon icon={faHome} />
                  <span className="span-button-back">Accueil</span>
                </button>
              </div>

              {/* <span className="span-victory">VICTOIRE !</span> */}
            </div>
          }
        </div>
      </div>
    );
  };
}

render(<App />, document.getElementById('root'));
export default App;
